import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import OfficeMap from "../components/OfficeMap"
import ContactForm from "../components/ContactForm"
import { BASE_BREADCRUMBS } from "../constants"
import orderBy from "lodash/orderBy"
import { useHierarchy } from "../context/hierarchy-context"
import "../page-styles/contact.scss"

const createDropdownData = (productLevelNode) => {
  let dropdownData = [{ code: '', name: '' }]
  const addSFCode = (node) => {
    if (node.salesforce_code && node.salesforce_code !== "Unassigned" &&
      !dropdownData.find(item => item.name === node.name)) {
      dropdownData.push({
        code: node.salesforce_code,
        name: node.name
      })
    }
  }
  productLevelNode?.children?.forEach(parent => {
    addSFCode(parent)
    parent.children?.forEach(child => {
      addSFCode(child)
      child.children?.forEach(grandchild => {
        addSFCode(grandchild)
      })
    })
  })
  dropdownData = orderBy(dropdownData, 'name', 'asc')
  dropdownData.push({
    code: "Other - Medical",
    name: "Other"
  })
  return dropdownData
}

const ContactPage = ({ location }) => {
  const { state = {} } = location
  const { hierarchy } = useHierarchy()
  return (
    <Layout
      pageTitle={"Contact\xa0Us"}
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/contact", name: "Contact" }]}
    >
      <Seo
        title="Contact Us | Typenex Medical"
        description="Have a question about our products or pricing? Click here to get in touch with Typenex Medical Supplies."
      />
      <div className="row">
        <div className="col-md-12 mb-3">
          <p>
            Need more information? Ready for a quote? Want to see a sample?
            Typenex&reg; Medical wants to hear from you.
          </p>
          <p>
            The Typenex Medical team is ready to respond to your institution's
            challenges. We combine the right products with smart service to
            create the simple solutions that support your unique processes and
            add healthcare value.
          </p>
          <p>
            Fill out the Typenex Medical contact form, and we will get in
            touch with you.
          </p>
        </div>
      </div>
      <section id="contact" className="row">
        <div className="col-md-8">
          <ContactForm
            selectedProd={state && state.selectedProduct}
            dropdownData={createDropdownData(hierarchy)} />
        </div>
        <div className="col-md-4">
          <OfficeMap />
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

