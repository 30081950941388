import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import FormLabel from "../FormLabel"
import "./styles.scss"
/* eslint-disable jsx-a11y/no-onchange */

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export const PureContactForm = ({ dropdownData, selectedProd, states, countries }) => {
  const [firstName, setFirstName] = useState("")
  const firstNameValid = () => /^[a-z ,.'-]+$/i.test(firstName)
  const [lastName, setLastName] = useState("")
  const lastNameValid = () => /^[a-z ,.'-]+$/i.test(lastName)
  const [email, setEmail] = useState("")
  const emailValid = () => /^[^ ]+@.+\..+$/.test(email)
  const [company, setCompany] = useState("")
  const companyValid = () => /^[\w! ,.'-]+/.test(company)
  const [phone, setPhone] = useState("")
  const phoneValid = () =>
    /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/.test(
      phone
    )
  const [selectedCountry, setSelectedCountry] = useState("")
  const countryValid = () => selectedCountry !== ""
  const [selectedState, setSelectedState] = useState("")
  const stateValid = () => selectedState !== ""
  const stateDisabled = () => {
    return selectedCountry !== "United States"
  }
  const selectedFromDropdown = dropdownData.find(e => {
    return (`${e?.name}_${e?.code}` === `${selectedProd?.name}_${selectedProd?.code}`)
  })
  const [selectedProduct, setSelectedProduct] = useState(selectedFromDropdown ? `${selectedFromDropdown?.name}_${selectedFromDropdown?.code}` : '')
  const productValid = () => {
    if (selectedProduct === "" || selectedProduct === "_") {
      return false
    }
    return dropdownData.some(e => `${e?.name}_${e?.code}` === selectedProduct)
  }
  const [description, setDescription] = useState("")
  const isDescriptionRequired = () =>
    !description && selectedProduct === "Other_Other - Medical"// {name_code} for the "Other" option
  const descriptionValid = () => description !== ""

  const [spinButton, setSpinButton] = useState(false)

  useEffect(() => {
    const flushData = () => {
      setFirstName("")
      setLastName("")
      setEmail("")
      setCompany("")
      setPhone("")
      setSelectedCountry("")
      setSelectedState("")
      setSelectedProduct("")
      setDescription("")
    }
    return () => flushData()
  }, [])

  // Note that Salesforce does not honor a retURL of localhost,
  // so you will never get redirected to the Thank You page when running "gatsby develop"
  let retURL
  if (typeof window !== "undefined") {
    retURL =
      window.location.protocol + "//" + window.location.host + "/thankyou/"
  }
  const salesforceOrgId = "00D500000007u5U"
  const salesforceProductRequestedField = "00N50000001auDP"

  const isSubmitReady = () => {
    return (
      firstNameValid() &&
      lastNameValid() &&
      emailValid() &&
      phoneValid() &&
      companyValid() &&
      productValid() &&
      (stateValid() || stateDisabled()) &&
      countryValid() &&
      (descriptionValid() || !isDescriptionRequired())
    )
  }

  return (
    <form
      className="contact-container"
      onSubmit={() => {
        if (isBrowser) { // This will not work in dev mode. Comment out "window.gtag" line for testing.
          window.gtag("event", "conversion", { send_to: "AW-627621910/VcmFCKyTxu0BEJaAo6sC" })
        }
        setSpinButton(true);
      }}
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="POST"
      autoComplete="off"
    >
      <input name="oid" type="hidden" value={salesforceOrgId} />
      <input name="retURL" type="hidden" value={retURL} />
      <input name={salesforceProductRequestedField} type="hidden" value={selectedProduct.split('_')[1]} />
      <div className="form-group row">
        <div className="col-sm-12 text-right form-word-required">
          <span className="asterisk">*</span>&nbsp;Required
        </div>
        <div className="col-md-6" data-cy="firstName" data-testid="first-name">
          <input name="lead_source" type="hidden" value="Website Organic" />
          <FormLabel label="First Name" validator={firstNameValid}>
            <input
              value={firstName}
              id="first_name"
              maxLength={40}
              name="first_name"
              type="text"
              className={`form-control input`}
              onChange={e => setFirstName(e.target.value)}
              required
            />
          </FormLabel>
        </div>
        <div className="col-md-6" data-cy="lastName">
          <FormLabel label="Last Name" validator={lastNameValid}>
            <input
              value={lastName}
              id="last_name"
              maxLength={40}
              name="last_name"
              type="text"
              className={`form-control input`}
              onChange={e => setLastName(e.target.value)}
              required
            />
          </FormLabel>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-6" data-cy="email">
          <FormLabel label="Email" validator={emailValid}>
            <input
              value={email}
              id="email"
              maxLength={40}
              name="email"
              type="text"
              className={`form-control input`}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </FormLabel>
        </div>
        <div className="col-md-6" data-cy="company">
          <FormLabel label="Company" validator={companyValid}>
            <input
              value={company}
              id="company"
              maxLength={40}
              name="company"
              type="text"
              className={`form-control input`}
              onChange={e => setCompany(e.target.value)}
              required
            />
          </FormLabel>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-4" data-cy="phone">
          <FormLabel label="Phone" validator={phoneValid}>
            <input
              value={phone}
              id="phone"
              maxLength={40}
              name="phone"
              type="text"
              className={`form-control input`}
              onChange={e => setPhone(e.target.value)}
              required
            />
          </FormLabel>
        </div>
        <div className="col-md-4" data-cy="country">
          <FormLabel label="Country" validator={countryValid}>
            <select
              name="country"
              className={`form-control input`}
              id="country"
              onChange={e => setSelectedCountry(e.target.value)}
              value={selectedCountry}
              required
            >
              {countries.nodes.map(country => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </FormLabel>
        </div>
        <div className="col-md-4" data-cy="state">
          <FormLabel
            label="State/Province"
            disabled={stateDisabled()}
            validator={stateValid}
          >
            <select
              name="state"
              className={`form-control input`}
              id="state"
              value={selectedState}
              onChange={e => setSelectedState(e.target.value)}
              required={!stateDisabled()}
              disabled={stateDisabled()}
            >
              {states.nodes.map(state => (
                <option key={state.name} value={state.code}>
                  {state.name}
                </option>
              ))}
            </select>
          </FormLabel>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-12 form-group" data-cy="product" data-testid="interested-in">
          <FormLabel label="Interested In" validator={productValid}>
            <select
              value={selectedProduct}
              className={`form-control input`}
              onChange={e => setSelectedProduct(e.target.value)}
              required
            >
              {dropdownData.map((product, ind) => (
                <option key={ind} value={`${product.name}_${product.code}`}>
                  {product.name}
                </option>
              ))}
            </select>
          </FormLabel>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-12 form-group" data-cy="additionalInformation">
          <FormLabel
            label="Additional Information"
            requiredMsg="Please enter some information so we can better help you"
            validator={descriptionValid}
            disabled={!isDescriptionRequired()}
          >
            <textarea
              id="description"
              value={description}
              name="description"
              type="text"
              className="form-control h-100"
              style={{ resize: "none" }}
              onChange={e => setDescription(e.target.value)}
            ></textarea>
          </FormLabel>
        </div>
      </div>
      <div className="form-group row">
        <div className="col pt-4 text-center">
          <button
            id="contact-submit"
            name="submit"
            className={`typenex-btn ${spinButton ? 'spinning' : ''}`}
            type="submit"
            value="Send Message"
            data-loading-text="Loading..."
            disabled={!isSubmitReady() || spinButton}
          >SEND MESSAGE
          </button>
        </div>
      </div>
    </form>
  )
}

export const ContactForm = ({ dropdownData, selectedProd }) => {
  const data = useStaticQuery(graphql`
    query ContactFormQuery {
      states: allStatesJson {
        nodes {
          name
          code
        }
      }
      countries: allCountriesJson {
        nodes {
          name
        }
      }
    }
  `)

  return <PureContactForm dropdownData={dropdownData} selectedProd={selectedProd} countries={data.countries} states={data.states} />
}

export default ContactForm
